<template>
  <v-container fluid v-if="variation">

    <v-row class="mb-2">
      <v-col cols="12" sm="6"> <h1>{{ translate(variation.country.name) }} / {{ translate(variation.sector.name) }}</h1></v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-btn color="primary" @click="download()" small> <v-icon small left>fa fa-download</v-icon> {{ $t('actions.download') }} </v-btn>
      </v-col>
    </v-row>

    <Data v-if="!loading" :data="data" config :lists="lists" />

  </v-container>
</template>

<script>
import {Component, Prop, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import {downloadToDisk} from "@/utils/download";
import Data from '@/pages/company/Data.vue';

@Component({components: { Data }, mixins: [formatter]})
export default class VariationView extends Vue {

  @Prop() variationId;

  loading = true;
  tab = 0;
  expandAll = false;
  years = [];
  lists = [];

  // received data
  variation = null;
  data = {
    financialStatements: [],
    aggregateHeadings: [],
    axes: [],
  };

  downloading = false;

  created() {

    this.$api.get(`variation/${this.variationId}`).then(response => {
      this.variation = response.data.variation;
      this.data.financialStatements = response.data.financialStatements;
      this.data.aggregateHeadings = response.data.aggregateHeadings;
      this.data.axes = response.data.axes;
      this.lists = response.data.lists;

      this.loading = false;
    });
  }



  download() {
    this.downloading = true;
    this.$api.post('variation/export?id=' + this.variation.id,  {},{
      responseType: 'blob'
    }).then(response => {
      this.downloading = false;
      downloadToDisk(response);
    });
  }

}
</script>
